import { Pagination50 } from "../../components/pageProps/shopPage/Pagination";
import { useState } from "react";
export default function Saxuravebi () {
    const [itemsPerPage, setItemsPerPage] = useState(48);
        const itemsPerPageFromBanner = (itemsPerPage) => {
        setItemsPerPage(itemsPerPage);
    };
    return(
        <div className="max-w-container mx-auto px-4">
              
        
              {/* <Breadcrumbs title="Products" /> */}
              {/* ================= Products Start here =================== */}
              <div className="w-full h-full flex pb-20 gap-10 mt-10">
                {/* <div className="w-[20%] lgl:w-[25%] hidden mdl:inline-flex h-full">
                  <ShopSideNav />
                </div> */}
                <div className="w-full mdl:w-[90%] lgl:w-[95%] h-full flex flex-col gap-10 ">
                  {/* <ProductBanner itemsPerPageFromBanner={itemsPerPageFromBanner} /> */}
                  <Pagination50 itemsPerPage={itemsPerPage} />
                </div>
              </div>
              {/* ================= Products End here ===================== */}
            </div>
    )
} 