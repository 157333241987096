import React, { useState } from 'react';
import Product from './Product';
import Dropdown from './Dropdown';
import { Link } from 'react-router-dom';
import './sidebar.css'
const Header = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const handleSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleLinkClick = () => {
        if (isSidebarOpen) {
            setIsSidebarOpen(false);
        }
    };
  return (
    <div className='scc'>
            <div className='second'>
                <div className="container">
                    <input type="checkbox" id="check" checked={isSidebarOpen} onChange={handleSidebarToggle} />
                    <div className="nav-btn">
                        <div className="nav-links">
                        <ul>
                            <li className="nav-link" style={{ '--i': '.6s' }}>
                                <Link className='sas' to="/" onClick={handleLinkClick}>მთავარი</Link>
                            </li>
                            <li className="nav-link" style={{ '--i': '.6s' }}>
                                <Link className='sas' to="/drainage" onClick={handleLinkClick}>სანიაღვრე სისტემა</Link>
                            </li>
                            <li className="nav-link" style={{ '--i': '.6s' }}>
                                <Link className='sas' to="/Roof" onClick={handleLinkClick}>დრეკადი კრამიტი</Link>
                            </li>
                            <li className="nav-link" style={{ '--i': '.85s' }}>
                                <a className='sas' href="#">ხის მასალა<i className="fas fa-caret-down"></i></a>
                                <div className="dropdown">
                                    <ul>
                                    <li className="dropdown-link">
                                            <Link to="/plywood" onClick={handleLinkClick}>ფანერა</Link>
                                        </li>
                                        <li className="dropdown-link">
                                            <Link to="/mukhissapekhuri" onClick={handleLinkClick}>კიბის საფეხური</Link>
                                        </li>
                                    <li className="dropdown-link">
                                            <Link to="/dvpone" onClick={handleLinkClick}>დვპ შეღებილი</Link>
                                        </li>
                                        <li className="dropdown-link">
                                            <Link to="/Osb" onClick={handleLinkClick}>ორიენტირებული ფილა Osb</Link>
                                        </li>
                                        <li className="dropdown-link">
                                            <Link to="/laminati" onClick={handleLinkClick}>ლამინირებული იატაკი</Link>
                                        </li>
                                      
                                       
                                        
                                        {/* <li className="dropdown-link">
                                            <a href="#"> </a>
                                        </li> */}
                                        {/* <li className="dropdown-link">
                                            <Link to="/Osb" onClick={handleLinkClick}>მერქანბურბუშელოვანი ფილა OSB</Link>
                                        </li> */}
                                        <div className="arrow"></div>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-link " style={{ '--i': '1.1s' }}>
                                <a className='sas' href="#">გამწმენდი სადგურები<i className="fas fa-caret-down"></i></a>
                                <div className="dropdown">
                                    <ul>
                                        <li className="dropdown-link">
                                            <Link to="/bioseptic" onClick={handleLinkClick}>ბიოლოგიური გამწმენდი სადგური</Link>
                                        </li>
                                        <li className="dropdown-link">
                                            <Link to="/septic" onClick={handleLinkClick}>ცხიმდამჭერი სადგური</Link>
                                        </li>
                                        <li className="dropdown-link">
                                            <Link to="/septicaccessories" onClick={handleLinkClick}>აქსესუარები</Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-link " style={{ '--i': '1.1s' }}>
                                <a className='sas' href="#">კერამიკა<i className="fas fa-caret-down"></i></a>
                                <div className="dropdown">
                                    <ul>
                                        <li className="dropdown-link">
                                            <Link to="/klinkeri" onClick={handleLinkClick}>კლინკერის ფილები</Link>
                                        </li>
                                        <li className="dropdown-link">
                                            <Link to="/keramograniti" onClick={handleLinkClick}>კერამოგრანიტი</Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                                 
                            <li className="nav-link" style={{ '--i': '1.35s' }}>
                                <Link to='/about' className='sas' onClick={handleLinkClick} >ჩვენს შესახებ</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="hamburger-menu-container">
                    <div className="hamburger-menu" style={{color: 'black'}}>
                        <div></div>
                    </div>
                </div>
            </div>
      </div>
    </div>
  );
};

export default Header;